import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import { loadLanguage, loadAllLanguages } from './spark/externals';

function setupI18n(options) {
    return createI18n(options);
}

export const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    fallbackWarn: false,
    numberFormats: {},
});

export async function loadPossibleLocales() {
    return await loadAllLanguages();
}

export async function loadLocaleMessages(locale) {
    let language = await loadLanguage(locale);
    if (!language) {
        language = (
            await import(/* webpackChunkName: "locales" */ `./dynamic-locales/${locale}.json`)
        ).default;
    }

    if (language?.messages) {
        i18n.global.setLocaleMessage(locale, language.messages);

        i18n.global.setNumberFormat(locale, language.number);

        i18n.global.setDateTimeFormat(locale, language.datetime);
    } else {
        i18n.global.setLocaleMessage(locale, language);
    }

    return nextTick();
}

export const oldLanguageMap = {
    en: 'en-CA',
    fr: 'fr-CA',
    es: 'es-US',
};
