export default class Spark_Holidays {
    constructor(p) {
        this._ = p;
    }

    /*
    Theme: {
        when: {
            year: number, optional
            month: number, optional
            day: number,
            duration: number (days),
            clients: [], optional - restrict theme to only certain clients
        }
        component: Name of component defined above
    }
*/

    themes = {
        christmas: {
            when: {
                month: 12,
                day: 20,
                duration: 10,
            },
            component: 'ChristmasTheme',
        },
        newyear: {
            when: {
                month: 12,
                day: 30,
                duration: 5,
            },
            component: 'NewYearTheme',
        },
        halloween: {
            when: {
                month: 10,
                day: 29,
                duration: 4,
            },
            component: 'HalloweenTheme',
        },
        julyfourth: {
            when: {
                month: 7,
                day: 4,
                duration: 1,
            },
            component: 'JulyFourthTheme',
        },
        canadaday: {
            when: {
                month: 7,
                day: 1,
                duration: 1,
            },
            component: 'CanadaDayTheme',
        },
        stpatrick: {
            when: {
                month: 3,
                day: 16,
                duration: 3,
            },
            component: 'StPatrickDayTheme',
        },
        valentines: {
            when: {
                month: 2,
                day: 14,
                duration: 1,
            },
            component: 'ValentinesDayTheme',
        },
        thanksgivingUS: {
            when: {
                year: 2024,
                month: 11,
                day: 28,
                duration: 3,
            },
            component: 'ThanksgivingTheme',
        },
        easter: {
            when: {
                year: 2024,
                month: 3,
                day: 30,
                duration: 4,
            },
            component: 'EasterTheme',
        },
    };

    checkThemeDate(year, month, day, when) {
        for (let j = 0; j < when.duration || 0; j++) {
            const date = new Date(when.year || 2020, when.month - 1, when.day + j);

            let valid = true;
            if (when.year && year != date.getFullYear()) {
                valid = false;
            }

            if (when.month && month != date.getMonth()) {
                valid = false;
            }

            if (when.day && day != date.getDate()) {
                valid = false;
            }

            if (valid) {
                return j + 1; //1 +
            }
        }

        return 0;
    }

    checkThemeClient(clients) {
        if (!clients || !clients.length) {
            return true;
        }

        const myClientID = this._.store.getters['config/configurationValue']('id');
        if (!myClientID) {
            return false;
        }

        return clients.indexOf(myClientID) > -1;
    }

    getHolidayComponentName(key) {
        return this.themes[key].component;
    }

    isInAHoliday() {
        const holiday = this._.Cookies.get('holiday');
        if (holiday) {
            return holiday;
        }

        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const day = now.getDate();

        for (let j in this.themes) {
            const inHoliday = this.checkThemeDate(year, month, day, this.themes[j].when);
            if (inHoliday > 0 && this.checkThemeClient(this.themes[j].when.clients)) {
                this._.Cookies.add('holiday', j, inHoliday);
                return j;
            }
        }
    }
}
