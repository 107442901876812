import localForage from 'localforage';

const store = localForage.createInstance({
    name: 'state',
});

async function fetchStoreSlice(prefix) {
    let values = {};
    try {
        await store.iterate((v, k) => {
            if (k.startsWith(prefix)) {
                values[k.replace(new RegExp('^' + prefix), '')] = v;
            }
        });
    } catch (e) {
        console.warn('failed to read ', store.name);
    }

    return values;
}

export const getItem = (key) => {
    return store.getItem(key);
};

export const deleteItem = (key) => {
    return store.removeItem(key);
};

export const listItems = (opts) => {
    return store
        .keys()
        .then((keys) => {
            return keys.filter((k) => {
                if (opts?.search !== undefined) {
                    return k.includes(opts.search);
                } else if (opts?.prefix !== undefined) {
                    return k.startsWith(opts.prefix);
                } else if (opts?.suffix !== undefined) {
                    return k.endsWith(opts.suffix);
                }
                return true;
            });
        })
        .catch(() => {
            return [];
        });
};

export const clearAll = () => {
    return store.clear();
};

export const setItem = (key, value) => {
    return store.setItem(key, value);
};

export const setStoreItem = (key, value) => {
    return setItem(`store/${key}`, value);
};

export const delStoreItem = (key) => {
    return deleteItem(`store/${key}`);
};

export async function fetchStoreState() {
    return fetchStoreSlice('store/');
}

const modulesToWatch = ['config', 'profile', 'campaigns', 'submissions'];
export const storageWatcher = (store) => {
    store.subscribe((mutation) => {
        const module = mutation.type.split('/')[0];
        if (modulesToWatch.includes(module)) {
            setStoreItem(module, store.getters[`${module}/getState`]);
        }
    });
};
