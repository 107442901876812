import { createStore } from 'vuex';
import { fetchStoreState, storageWatcher } from './local';

import { configModule } from './modules/config';
import { profileModule } from './modules/profile';
import { submissionsModule } from './modules/submissions';
import { campaignsModule } from './modules/campaigns';

export const store = createStore({
    plugins: [storageWatcher],
    modules: {
        config: configModule,
        profile: profileModule,
        campaigns: campaignsModule,
        submissions: submissionsModule,
    },
    state() {
        return {
            initialized: false,
        };
    },
    actions: {
        async initialize(context) {
            return fetchStoreState()
                .then((r) => {
                    Object.keys(r).forEach((key) => {
                        context.commit(`${key}/initialize`, JSON.parse(r[key]));
                    });
                })
                .catch((e) => {
                    console.warn(e);
                });
        },
        async completedInitalization({ state }) {
            state.initialized = true;
        },
        isInitialized(context) {
            return new Promise((resolve) => {
                let i = setInterval(() => {
                    if (context.state.initialized) {
                        clearInterval(i);
                        resolve();
                    }
                }, 25);
            });
        },
    },
});
