import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

const _getChatsFile = async function() {
    let opts = {
        path: 'chats.json',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
    };
    let file = [];

    try {
        const res = await Filesystem.readFile(opts);
        file = JSON.parse(res?.data) || [];
    } catch (e) {
        console.log('Missing chats file', e);
    }

    return file;
};

const _saveChatsFile = async function(content) {
    try {
        await Filesystem.writeFile({
            path: 'chats.json',
            data: content,
            directory: Directory.Data,
            encoding: Encoding.UTF8,
            recursive: true,
        });
    } catch (e) {
        console.warn('Failed to save chats file', e);
        return false;
    }

    return true;
};

const _deleteChatsFile = async function() {
    try {
        await Filesystem.deleteFile({
            path: 'chats.json',
            directory: Directory.Data,
        });
    } catch (e) {
        console.warn('Failed to delete chats file', e);
    }
};

export default class Spark_Chats {
    constructor(p) {
        this._ = p;
    }

    async save(item) {
        let file = await _getChatsFile();
        file.filter((c) => {
            return c.ID != item.ID;
        });
        file.push(item);

        file = JSON.stringify(file);

        await _saveChatsFile(file);

        if (await this._.API.saveChats(file)) {
            await _deleteChatsFile();
        } else {
            console.log('Must be offline...');
        }
    }
}
