export const submissionsModule = {
    state: () => ({
        ids: {},
        list: [],
        lastFetched: {},
        largestRange: {},
    }),
    namespaced: true,
    mutations: {
        initialize: (state, s) => {
            //get rid of anything we shouldn't have
            for (let j in s) {
                state[j] = s[j];
            }
        },
        setLastFetched: (state, { lastFetched, campaign }) => {
            state.lastFetched[campaign] = lastFetched.toISOString();
        },
        setLargestRange: (state, { largestRange, campaign }) => {
            if (
                !state.largestRange[campaign] ||
                largestRange < new Date(state.largestRange[campaign])
            ) {
                state.largestRange[campaign] = largestRange.toISOString();
            }
        },
        addMany: (state, ss) => {
            ss.forEach((s) => {
                if (state.ids[s.ID] !== undefined) {
                    //if this submission is already here, just update any existing info
                    for (let j in s) {
                        state.list[state.ids[s.ID]][j] = s[j];
                    }

                    return;
                }

                if (Object.keys(s).length > 1) {
                    s._unix = new Date(s.Created).valueOf();
                    state.list.push(s);
                    state.ids[s.ID] = state.list.length - 1;
                }
            });
        },
    },
    getters: {
        getState: (state) => {
            //can filter and exclude certain fields if we don't want to save their state
            return JSON.stringify(state);
        },
        getLastFetched: (state) => (campaign) => {
            if (!state.lastFetched[campaign]) {
                return false;
            }
            return new Date(state.lastFetched[campaign]);
        },
        getLargestRange: (state) => (campaign) => {
            if (!state.largestRange[campaign]) {
                return false;
            }
            return new Date(state.largestRange[campaign]);
        },
        /*
        Get a subset of the stored submissions, by any known field, max age, and/or a limit #
        filters: {
            fields: {
                Campaign: 'abc123',
                Team: 'bbbbbb',
            },
            age: 1 // hours
            today: true
            limit: 10, 25, 30
        }
        */
        getBy: (state) => (filters) => {
            let list = state.list;
            let max_age = false;
            let min_age = false;
            if (filters?.age) {
                max_age = Date.now() - filters.age * 3600000; //1h in ms
            } else if (filters?.today) {
                //set the floor to "today"
                max_age = Date.now() - (Date.now() % 86400);
            }

            if (filters?.min_age) {
                min_age = Date.now() - filters.min_age * 3600000;
            }

            if (filters?.fields || max_age) {
                list = state.list.filter((c) => {
                    //if we have any filters.fields? make sure we're good
                    if (
                        filters.fields &&
                        !Object.keys(filters.fields).every((v) => {
                            return (
                                c[v] == filters.fields[v] ||
                                new RegExp(filters.fields[v]).test(c[v])
                            );
                        })
                    ) {
                        return false;
                    }

                    //if we have an age limit, check we're good there too
                    if (max_age && c._unix < max_age) {
                        return false;
                    }

                    if (min_age && c._unix > min_age) {
                        return false;
                    }

                    return true;
                });
            }

            list = list.sort((a, b) => {
                return b._unix - a._unix;
            });

            if (filters?.limit) {
                //and let's limit to the requested amount, if there is any
                return list.slice(0, filters.limit);
            }

            return list;
        },
        getByCount: (_, getters) => (filters) => {
            return getters.getBy(filters).length;
        },
    },
};
