export default class Spark_Defaults {
    constructor(p) {
        this._ = p;
    }

    getDashpage(mode, campaignType) {
        if (mode == 'global') {
            return [
                {
                    field: 'CampaignName',
                    title: '$dashboard.list.campaign',
                    width: '30%',
                    align: 'left',
                    alternative: 'CampaignAcronym',
                },
                {
                    field: 'ID',
                    title: 'ID',
                    change: {
                        type: 'allcaps',
                    },
                },
                {
                    field: '=Name',
                    title: '$dashboard.list.donor',
                },
                {
                    field: 'Created',
                    title: '$dashboard.list.date',
                    change: {
                        type: 'date',
                    },
                },
                {
                    field: 'PaymentStatus',
                    title: '$dashboard.list.status',
                    change: {
                        type: 'caps',
                        color: [
                            {
                                match: 'approved|pending',
                                color: 'green',
                            },
                            {
                                match: 'declined|unprocessed',
                                color: 'red',
                            },
                            {
                                match: '.*',
                                color: 'yellow',
                            },
                        ],
                    },
                },
            ];
        }
        if (campaignType == 'in-person') {
            return [
                {
                    field: 'ID',
                    title: 'ID',
                    change: {
                        type: 'allcaps',
                    },
                },
                {
                    field: 'Created',
                    title: '$dashboard.list.date',
                    change: {
                        type: 'date',
                    },
                },
                {
                    field: '=Name',
                    title: '$dashboard.list.donor',
                },
                {
                    field: 'Amount',
                    title: '$dashboard.list.amount',
                    change: {
                        type: 'money',
                    },
                },
                {
                    field: 'PaymentStatus',
                    title: '$dashboard.list.status',
                    change: {
                        color: [
                            {
                                match: 'approved|pending',
                                color: 'green',
                            },
                            {
                                match: 'declined|unprocessed',
                                color: 'red',
                            },
                            {
                                match: '.*',
                                color: 'yellow',
                            },
                        ],
                        map: '$statuses.*',
                    },
                },
            ];
        }
        if (campaignType == 'self-serve') {
            return [
                {
                    field: 'ID',
                    title: 'ID',
                    change: {
                        type: 'allcaps',
                    },
                },
                {
                    field: 'Created',
                    title: '$dashboard.list.date',
                    change: {
                        type: 'date',
                    },
                },
                {
                    field: '=Name',
                    title: '$dashboard.list.donor',
                },
                {
                    field: 'Amount',
                    title: '$dashboard.list.amount',
                    change: {
                        type: 'money',
                    },
                },
                {
                    field: 'PaymentStatus',
                    title: '$dashboard.list.status',
                    change: {
                        type: 'caps',
                        color: [
                            {
                                match: 'approved|pending',
                                color: 'green',
                            },
                            {
                                match: 'declined|unprocessed',
                                color: 'red',
                            },
                            {
                                match: '.*',
                                color: 'yellow',
                            },
                        ],
                    },
                },
            ];
        }
        if (campaignType == 'voip') {
            return [
                {
                    field: 'ID',
                    title: 'ID',
                    change: {
                        type: 'allcaps',
                    },
                },
                {
                    field: 'Created',
                    title: '$dashboard.list.date',
                    change: {
                        type: 'date',
                    },
                },
                {
                    field: '=Name',
                    title: '$dashboard.list.donor',
                },
                {
                    field: 'callStatus',
                    title: '$dashboard.list.leadstatus',
                    change: {
                        type: 'caps',
                    },
                },
                {
                    field: 'PaymentStatus',
                    title: '$dashboard.list.status',
                    change: {
                        type: 'caps',
                        color: [
                            {
                                match: 'approved|pending',
                                color: 'green',
                            },
                            {
                                match: 'declined|unprocessed',
                                color: 'red',
                            },
                            {
                                match: '.*',
                                color: 'yellow',
                            },
                        ],
                    },
                },
            ];
        }

        return [];
    }

    getStatBoxes(mode, campaignType) {
        const customStats = this._.store.getters['config/configurationValue']('stats');
        if (customStats?.all) {
            return customStats.all;
        }

        if (customStats?.[campaignType]) {
            return customStats?.[campaignType];
        }

        if (customStats?.[mode]) {
            return customStats?.[mode];
        }

        if (mode == 'global' || campaignType == 'in-person') {
            return [
                {
                    title: '$dashboard.statboxes.myscore',
                    icon: 'check_circle_alt',
                    color: 'green',
                    type: 'count',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
                {
                    title: '$dashboard.statboxes.highscore',
                    icon: 'star',
                    color: 'purple',
                    type: 'max',
                    division: 'day',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                    meta: {
                        range: ['lastmonth', 'month', 'week'],
                    },
                },
                {
                    title: '$dashboard.statboxes.avgsubtime',
                    icon: 'clock',
                    color: 'orange',
                    type: 'avgtime',
                    fields: ['Meta_duration'],
                },
                {
                    title: '$dashboard.statboxes.totalraised',
                    icon: 'globe',
                    color: 'blue',
                    type: 'totalamount',
                    fields: ['Amount'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
            ];
        }
        if (campaignType == 'self-serve') {
            return [
                {
                    title: '$dashboard.statboxes.myscore',
                    icon: 'check_circle_alt',
                    color: 'green',
                    type: 'count',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
                {
                    title: '$dashboard.statboxes.highscore',
                    icon: 'star',
                    color: 'purple',
                    type: 'max',
                    division: 'day',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
                {
                    title: '$dashboard.statboxes.started',
                    icon: 'chat',
                    color: 'orange',
                    type: 'count',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'submitted',
                        },
                    ],
                },
                {
                    title: '$dashboard.statboxes.totalraised',
                    icon: 'globe',
                    color: 'blue',
                    type: 'totalamount',
                    fields: ['Amount'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
            ];
        }
        if (campaignType == 'voip') {
            return [
                {
                    title: '$dashboard.statboxes.myscore',
                    icon: 'check_circle_alt',
                    color: 'green',
                    type: 'count',
                    fields: ['PaymentStatus'],
                    include: [
                        {
                            field: 'PaymentStatus',
                            value: 'approved|pending',
                        },
                    ],
                },
                {
                    title: '$dashboard.statboxes.totalcalltime',
                    icon: 'star',
                    color: 'purple',
                    type: 'totaltime',
                    setup: {
                        timeIn: 'seconds',
                    },
                    fields: ['=totalCallTimeSeconds'],
                },
                {
                    title: '$dashboard.statboxes.started',
                    icon: 'chat',
                    color: 'orange',
                    type: 'count',
                },
                {
                    title: '$dashboard.statboxes.avgcalltime',
                    icon: 'clock',
                    color: 'pink',
                    type: 'avgtime',
                    setup: {
                        timeIn: 'seconds',
                    },
                    fields: ['=totalCallTimeSeconds'],
                },
            ];
        }

        return [];
    }

    getLeaderboards(mode, campaignType) {
        const customStats = this._.store.getters['config/configurationValue']('leaderboards');
        if (customStats?.all) {
            return customStats.all;
        }

        if (customStats?.[campaignType]) {
            return customStats?.[campaignType];
        }

        if (customStats?.[mode]) {
            return customStats?.[mode];
        }

        //mode, campaignType
        return [
            {
                title: '$dashboard.top5',
            },
            {
                title: '$dashboard.conversions',
                fields: ['PaymentStatus'],
                include: [
                    {
                        field: 'PaymentStatus',
                        value: 'approved|pending',
                    },
                ],
            },
            {
                title: '$dashboard.veraddress',
                fields: ['Meta_addressValidated'],
                include: [
                    {
                        field: 'Meta_addressValidated',
                        value: true,
                    },
                ],
            },
            {
                title: '$dashboard.veremail',
                fields: ['Meta_emailValidated'],
                include: [
                    {
                        field: 'Meta_emailValidated',
                        value: true,
                    },
                ],
            },
            {
                title: '$dashboard.scannedid',
                fields: ['Meta_idScanned'],
                include: [
                    {
                        field: 'Meta_idScanned',
                        value: true,
                    },
                ],
            },
            {
                title: '$dashboard.scannedcc',
                fields: ['Meta_ccScanned'],
                include: [
                    {
                        field: 'Meta_ccScanned',
                        value: true,
                    },
                ],
            },
        ];
    }
}
