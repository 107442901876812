export const campaignsModule = {
    state: () => ({
        campaigns: {},
        assets: {},
        assetLists: {},
    }),
    namespaced: true,
    mutations: {
        initialize: (state, s) => {
            //get rid of anything we shouldn't have
            for (let j in s) {
                state[j] = s[j];
            }
        },
        addCampaign: (state, campaign) => {
            state.campaigns[campaign.id] = campaign;
        },
        addAssetList: (state, { id, assets }) => {
            if (!state.assets[id]) {
                state.assets[id] = {};
            }

            state.assetLists[id] = assets;
        },
        addAsset: (state, { id, name, path, lastModified }) => {
            state.assets[id][name] = {
                path,
                lm: lastModified,
            };
        },
        finishedLoading: (state, id) => {
            delete state.campaigns[id]._failed;
            state.campaigns[id]._loaded = true;
        },
        failedLoading: (state, id) => {
            if (!state.campaigns[id]) {
                state.campaigns[id] = {};
            }
            state.campaigns[id]._failed = true;
        },
        forbidden: (state, id) => {
            if (!state.campaigns[id]) {
                state.campaigns[id] = {};
            }
            state.campaigns[id]._forbidden = true;
        },
        allowed: (state, id) => {
            if (!state.campaigns[id]) {
                state.campaigns[id] = {};
            }
            delete state.campaigns[id]._forbidden;
        },
        missingProducts: (state, id) => {
            state.campaigns[id]._products_missing = true;
        },
        foundProducts: (state, id) => {
            delete state.campaigns[id]._products_missing;
        },
    },
    getters: {
        getState: (state) => {
            //can filter and exclude certain fields if we don't want to save their state
            return JSON.stringify(state);
        },
        getOne: (state) => (id) => {
            return state.campaigns[id];
        },
        getAssetList: (state) => (id) => {
            return state.assetLists[id] ?? [];
        },
        getAssetMap: (state) => (id) => {
            return state.assets[id] ?? {};
        },
        isYamlForm: (state) => (id) => {
            let yamlFound = false;
            let jsonFound = false;
            (state.assetLists?.[id] || []).forEach((c) => {
                if (c.File == 'form.yaml') {
                    yamlFound = true;
                } else if (c.File == 'form.json') {
                    jsonFound = true;
                }
            });

            //important to know if we're looking at a campaign that doesn't have a form.json
            return yamlFound && !jsonFound;
        },
        getCampaignType: (state) => (id) => {
            return state.campaigns[id]?.type;
        },
        getAssetItem: (state) => (id, name) => {
            return state.assets?.[id]?.[name];
        },
        getLoadingState: (state) => (id) => {
            return state.campaigns[id]?._loaded;
        },
        getFailedState: (state) => (id) => {
            return state.campaigns[id]?._failed;
        },
        getForbiddenState: (state) => (id) => {
            return state.campaigns[id]?._forbidden;
        },
        getClosed: (state) => (id) => {
            return state.campaigns[id]?.closed;
        },
    },
};
