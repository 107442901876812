export default class Spark_Cookies {
    constructor(p) {
        this._ = p;
    }

    add(name, content, days = 0) {
        const d = new Date();
        d.setTime(d.getTime() + days * 25 * 60 * 60 * 1000);

        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + content + ';' + expires;
    }

    get(name) {
        const cookies = decodeURIComponent(document.cookie).split(';');
        for (let j = 0; j < cookies.length; j++) {
            if (cookies[j].trim().startsWith(name + '=')) {
                return cookies[j].trim().substring(cookies[j].trim().indexOf('=') + 1);
            }
        }
    }

    remove(name) {
        document.cookie = name + '=; expires=Thu 01 Jan 1970 00:00:00 UTC;';
    }
}
