/*
    Abstract away the complexities of managing the product element pictures.
    We should rely on them being cached in storage after at least one use.

    images that have not been modified in less than 60 days are deleted to avoid slowly running out of storage
*/

import { getFileStats, downloadFileTo, readDir, deleteFile } from '../filesystem';

const ProductImageManager = {
    platform: '',
    toLoad: {},
    loading: {},
    fetchers: [false, false, false, false],
    load({ campaign, url, platform }, callback) {
        this.platform = platform;

        if (!this.toLoad[url]) {
            this.toLoad[url] = {
                campaign,
                callbacks: [],
            };
        }
        this.toLoad[url].callbacks.push(callback);

        for (let j = 0; j < this.fetchers.length; j++) {
            if (!this.fetchers[j]) {
                this.fetch(j);
            }
        }
    },

    async fetch(workerIndex) {
        this.fetchers[workerIndex] = true;
        let url;
        let index = 0;

        do {
            url = Object.keys(this.toLoad)[index];
            index++;
        } while (this.loading[url]);
        if (!url) {
            this.fetchers[workerIndex] = false;
            return;
        }
        this.loading[url] = true;

        const config = this.toLoad[url];
        const productimage = url.substring(url.lastIndexOf('/') + 1).replace(/ /g, '_');
        const key = `products/${config.campaign}/${productimage}`;

        if (this.platform == 'web') {
            this.respond({
                url,
                workerIndex,
                localUrl: url,
            });
            return;
        }

        const asset = await getFileStats(key);
        if (asset) {
            this.respond({
                url,
                workerIndex,
                localUrl: asset?.uri ?? false,
            });
            return; //;
        }

        const downloadResult = await downloadFileTo(url, false, key);
        if (!downloadResult) {
            this.respond({
                url,
                workerIndex,
                localUrl: false,
            });
            return; //error?
        }

        const downloadedAsset = await getFileStats(key);
        this.respond({
            url,
            workerIndex,
            localUrl: downloadedAsset?.uri ?? false,
        });
    },

    respond({ url, workerIndex, localUrl }) {
        this.toLoad[url].callbacks.forEach((c) => {
            c(localUrl);
        });
        delete this.toLoad[url];
        delete this.loading[url];

        this.fetch(workerIndex);
    },

    //clean up older product images so we don't slowly build up old unneeded files
    async cleanUp() {
        const maxAge = 60 * 86400000; //60 days
        const now = Date.now();

        let campaigns = await readDir('products');
        if (!campaigns) {
            campaigns = {
                files: [],
            };
        }

        campaigns.files.forEach(async (c) => {
            let images = await readDir(`products/${c}`);
            if (!images) {
                images = {
                    files: [],
                };
            }

            images.files.forEach(async (i) => {
                const stats = await getFileStats(`products/${c}/${i}`);
                if (now - stats?.mtime > maxAge) {
                    deleteFile(`products/${c}/${i}`);
                }
            });
        });
    },
};

export default ProductImageManager;
