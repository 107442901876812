import { createRouter, createWebHashHistory } from 'vue-router';

const HomeView = () => import(/* webpackChunkName: "view-home" */ './views/RootPage.vue');
const ContactView = () => import(/* webpackChunkName: "view-contact" */ './views/ContactPage.vue');
const ReleasesView = () =>
    import(/* webpackChunkName: "view-releases" */ './views/ReleasesPage.vue');
const LoginView = () => import(/* webpackChunkName: "view-login" */ './views/LoginPage.vue');
const CampaignsView = () =>
    import(/* webpackChunkName: "view-campaigns" */ './views/CampaignsPage.vue');
const DashboardView = () =>
    import(/* webpackChunkName: "view-dashboard" */ './views/DashboardPage.vue');
const CampaignView = () =>
    import(/* webpackChunkName: "view-campaign" */ './views/CampaignPage.vue');
const KioskView = () => import(/* webpackChunkName: "view-kiosk" */ './views/KioskPage.vue');
const FormView = () => import(/* webpackChunkName: "view-form" */ './views/FormPage.vue');

const PlayGroundView = () =>
    import(/* webpackChunkName: "view-playground" */ './views/PlaygroundPage.vue');

export function setupRouter(i18n, spark) {
    const routes = [
        {
            path: '/campaigns',
            name: 'campaigns',
            component: CampaignsView,
            meta: {
                requiresAuth: true,
                resumable: true,
                transition: 'fade',
                topbar: 'dark',
            },
        },
        {
            path: '/dashboard/:campaign',
            name: 'dashboard',
            component: DashboardView,
            meta: {
                requiresAuth: true,
                resumable: true,
                transition: 'fade',
                topbar: 'light',
            },
        },
        {
            path: '/kiosk/:campaign',
            name: 'kiosk',
            component: KioskView,
            meta: {
                requiresAuth: true,
                transition: 'fade',
                topbar: 'dark',
            },
        },
        {
            path: '/campaign/:campaign',
            name: 'campaign',
            component: CampaignView,
            meta: {
                requiresAuth: true,
                resumable: true,
                transition: 'fade',
                topbar: 'dark',
            },
        },
        {
            path: '/form/:campaign',
            name: 'form',
            component: FormView,
            meta: {
                requiresAuth: true,
                transition: 'fade',
                topbar: 'dark',
            },
        },
        {
            path: '/contact',
            name: 'contact',
            component: ContactView,
            meta: {
                transition: 'fade',
                resumable: false,
                topbar: 'light',
            },
        },
        {
            path: '/releases',
            name: 'releases',
            component: ReleasesView,
            meta: {
                transition: 'fade',
                resumable: false,
                topbar: 'light',
            },
        },
        {
            path: '/playground',
            name: 'playground',
            component: PlayGroundView,
            meta: {
                transition: 'fade',
                resumable: false,
                topbar: 'light',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {
                transition: 'fade',
                resumable: true,
                topbar: 'light',
            },
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'home',
            component: HomeView,
        },
    ];

    const router = createRouter({
        history: createWebHashHistory(),
        routes,
    });

    router.beforeEach(async (to) => {
        //only draw the page once the storage has re-initialized
        await spark.store.dispatch('isInitialized');

        if (to.meta?.requiresAuth) {
            if (!spark.ensureAuth()) {
                //ensure that if the page requires it, we are authed
                router.replace({
                    path: '/',
                });
            }
        }

        return true;
    });

    router.afterEach((to) => {
        if (to.title != 'home') {
            //after picking the page, set page title, color scheme, etc
            spark.Analytics.PageView(to.path);
            document.title = i18n.global.t(`${to.name}.title`);

            if (to.meta?.resumable) {
                spark.store.commit('profile/lastPage', to.fullPath);
            }

            if (to.meta?.topbar) {
                switch (to.meta.topbar) {
                    case 'light':
                        spark.Helpers.setStatusBarForDarkPage();
                        break;
                    case 'dark':
                        spark.Helpers.setStatusBarForLightPage();
                        break;
                }
            }
        }
    });

    return router;
}
