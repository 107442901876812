import { readDir, saveToStorage, getFileStats, deleteFile, getFile } from './filesystem';
import { getItem, deleteItem } from '../storage/local';

export default class Spark_Queue {
    constructor(p) {
        this._ = p;
    }

    waitings = {};
    uploading = {};
    statuses = {
        UNPROCESSED: 'unprocessed',
        APPROVED: 'approved',
        SUBMITTED: 'submitted',
        DECLINED: 'declined',
        PENDING: 'pending',
        QUEUED: 'queued',
        ERROR: 'error',
        DUPLICATE: 'duplicate',
        COMPLETED: 'completed',
        UNKNOWN: 'unknown',
    };
    states = {
        QUEUED: 'queued',
        UPLOADED: 'uploaded',
        NOTWAITING: 'notwaiting',
        VALID: 'valid',
        INVALID: 'invalid',
        NOTSAVED: 'notsaved',
    };

    acceptedStatus(status) {
        return (
            status == this.statuses.APPROVED || //cc -> approved
            status == this.statuses.PENDING || //bank -> approved
            status == this.statuses.SUBMITTED //partial completion, spark is done with it
        );
    }

    start() {
        if (this._.store.getters['config/configurationValue']('stop_uploads')) {
            //don't want to upload anything at the moment
            return;
        }
        if (this.loop) {
            //already started
            return;
        }

        console.log('Submission Queue started');
        this.listSubmissions();
        this.resume();
    }

    resume() {
        const interval = 2 * 60 * 1000; //2m loop
        this.loop = setInterval(() => {
            this.listSubmissions();
        }, interval);
    }

    pause() {
        clearInterval(this.loop);
    }

    async processSubmission(name, zip, params, settings = {}) {
        //zip is a "string" here
        const uid = (Math.random() * 100).toFixed(0);
        const zipName = `${uid}_${name}.ezip`;
        const zipUploadName = `${name}.ezip`;

        const paramName = `${uid}_${name}`;
        const subid = name.split('_')[0];

        const preventUploading = this._.store.getters['config/configurationValue']('stop_uploads');

        let savedCorrectly = true;

        if (this.waitings[subid]) {
            return; //we already have a sub with the same id, don't make another
        }
        this.addLock(subid);
        this.waitings[subid] = settings;

        const savedZip = await this.saveEZip(zipName, zip);
        const savedParams = await this.saveParams(paramName, params);

        //remove cached item.
        deleteItem('cachedsub');

        if (!savedZip || !savedParams) {
            savedCorrectly = false;
        }

        if (preventUploading) {
            if (savedCorrectly) {
                settings.listen({
                    id: subid,
                    state: this.states.QUEUED,
                });
                this._.Messages.addMessage({
                    id: subid,
                    severity: this._.Messages.levels.WARNING,
                    message: {
                        type: 'queued_submission',
                        id: subid,
                    },
                });
            } else {
                settings.listen({
                    id: subid,
                    state: this.states.NOTSAVED,
                });
            }

            delete this.waitings[subid];
            this.removeLock(subid);
            return;
        }

        const uploaded = await this.uploadSubmission(zipUploadName, zip, params);
        if (!uploaded && !savedCorrectly) {
            //failed to upload. Not saved
            settings.listen({
                id: subid,
                state: this.states.NOTSAVED,
            });

            delete this.waitings[subid];
            this.removeLock(subid);
            return;
        }

        if (!uploaded && savedCorrectly) {
            //failed to upload. But Saved
            settings.listen({
                id: subid,
                state: this.states.QUEUED,
            });
            this._.Messages.addMessage({
                id: subid,
                severity: this._.Messages.levels.WARNING,
                message: {
                    type: 'queued_submission',
                    id: subid,
                },
            });

            delete this.waitings[subid];
            this.removeLock(subid);
            return;
        }

        //uploaded correctly, remove local infos
        deleteFile(`queue/${zipName}`);
        deleteFile(`encrypts/${paramName}`);
        this._.Messages.removeMessage(subid);

        const isPaused = await getItem('pause-processing');
        if (!settings.shouldListen || isPaused) {
            settings.listen({
                id: subid,
                state: this.states.NOTWAITING,
            });

            delete this.waitings[subid];
            this.removeLock(subid);
            return;
        }

        this.watchForOutcome(subid);
    }

    async uploadSubmission(name, zip, paramString) {
        if (typeof zip == 'string') {
            //convert string -> blob -> formData
            zip = new Blob([zip], {
                type: 'application/zip',
            });
        }

        const params = {
            headers: JSON.parse(paramString),
            filename: name,
            timeout: 60000,
            retries: 3,
        };

        const isPaused = await getItem('pause-processing');
        if (isPaused) {
            params.headers['x-onboard-block-queue'] = '1';
        }

        console.log(`Uploading ${name}`);
        this._.Analytics.Info('queue', 'upload-attempt', name);
        const outcome = await this._.API.post('accounts/incoming/', zip, params);
        if (!outcome) {
            this._.Analytics.Exception('queue', 'upload-failed', name);
        } else {
            this._.Analytics.Info('queue', 'upload-success', name);
        }

        return outcome;
    }

    async saveEZip(zipName, zip) {
        await saveToStorage(`queue/${zipName}`, zip);
        const stats = await getFileStats(`queue/${zipName}`);
        if (!stats) {
            // failed to save to storage
            return false;
        }

        return true;
    }

    async saveParams(paramName, params) {
        await saveToStorage(`encrypts/${paramName}`, params, true);
        const stats = await getFileStats(`encrypts/${paramName}`);
        if (!stats) {
            return false;
        }
        return true;
    }

    async watchForOutcome(subid) {
        const waitingFor = this.waitings[subid];
        let polled = 0;
        const max = 30; //30 attempts
        const buffer = 1; //1s buffer between attempts
        const delay = 5; //5s delay
        let state = false;

        await this._.Helpers.delay(delay * 1000);
        do {
            //check status && reason
            state = await this._.API.getAccountState(subid);
            if (
                state &&
                state.PaymentStatus != this.statuses.QUEUED &&
                state.PaymentStatus != this.statuses.UNPROCESSED
            ) {
                if (this.acceptedStatus(state.PaymentStatus)) {
                    waitingFor.listen({
                        id: subid,
                        state: this.states.VALID,
                        status: state.PaymentStatus,
                    });
                } else {
                    waitingFor.listen({
                        id: subid,
                        state: this.states.INVALID,
                        status: state.PaymentStatus,
                        error_reason: state.TransactionErrorReason,
                    });
                }

                delete this.waitings[subid];
                this.removeLock(subid);

                this._.store.commit('submissions/addMany', [state]);

                return;
            }

            await this._.Helpers.delay(buffer * 1000);
        } while (++polled < max);

        if (state) {
            //it was uploaded, and is in DB, but is still marked as unprocessed or queued
            waitingFor.listen({
                id: subid,
                state: this.states.UNPROCESSED,
                status: state.PaymentStatus,
                error_reason: state.TransactionErrorReason,
            });

            delete this.waitings[subid];
            this.removeLock(subid);
            return;
        }

        //if we never replied in the loop, we ran out of time to wait
        waitingFor.listen({
            id: subid,
            state: this.states.UPLOADED,
        });

        delete this.waitings[subid];
        this.removeLock(subid);
    }

    async getStoredSubmissions() {
        return await readDir('queue');
    }

    async listSubmissions() {
        console.log('Listing Queued Submissions...');
        //if not logged in, don't try to upload anything
        const valid = this._.store.getters['profile/getTokenValidity'];
        if (!valid) {
            return;
        }

        //get list of files in /queue directory
        const queuedSubmissions = await this.getStoredSubmissions();
        if (!queuedSubmissions?.files?.length) {
            return;
        }

        do {
            const sub = queuedSubmissions.files.shift();
            await this.verifyIntegrity(sub, true);
            await this._.Helpers.delay(50);
        } while (queuedSubmissions.files.length);
    }

    async cleanQueuedSubmissions() {
        const queuedSubmissions = await this.getStoredSubmissions();
        if (!queuedSubmissions?.files?.length) {
            return;
        }

        let days = 7;
        const clientDays = parseInt(
            this._.store.getters['config/configurationValue']('max_queue_age')
        );
        if (!isNaN(clientDays) && clientDays > 0) {
            days = clientDays;
        }

        const maxAge = days * 86400000; //7 days by default
        const now = Date.now();

        queuedSubmissions.files.forEach(async (i) => {
            const stats = await getFileStats(`queue/${i}`);
            if (now - stats?.mtime > maxAge) {
                deleteFile(`queue/${i}`);
            }
        });
    }

    async verifyIntegrity(sub, fromQueue = false) {
        const zipName = sub.name;
        const ids = zipName.replace('.ezip', '').split('_');

        const subid = ids[1];
        const txid = ids[2];

        const zipUploadName = `${subid}_${txid}.ezip`;
        const paramName = zipName.replace('.ezip', '');

        console.log('Try uploading ', subid, JSON.stringify(this.waitings));
        if (this.checkLock(subid)) {
            console.log('Already being uploaded', subid);
            return;
        }

        this.addLock(subid);
        this._.Messages.addMessage({
            id: subid,
            severity: this._.Messages.levels.WARNING,
            message: {
                type: 'queued_submission',
                id: subid,
            },
        });

        let { valid, ezip, params } = await this.verifyEzipAndParams(zipName, paramName);
        if (!valid) {
            console.warn(`Queued submission does not appear valid ${zipName} or ${paramName}.`);

            this.removeLock(subid);
            deleteFile(`queue/${zipName}`);
            deleteFile(`encrypts/${paramName}`);
            return;
        }

        const stillUnique = await this.verifyUniqueIds(subid, txid);
        if (!stillUnique) {
            this.removeLock(subid);
            deleteFile(`queue/${zipName}`);
            deleteFile(`encrypts/${paramName}`);
            return;
        }

        if (fromQueue) {
            try {
                const tmp = JSON.parse(params);
                tmp['x-onboard-queued'] = '1';
                params = JSON.stringify(tmp);
            } catch (e) {
                console.warn('Failed to parse params', e);
            }
        }

        const uploaded = await this.uploadSubmission(zipUploadName, ezip, params);
        this.removeLock(subid);

        if (!uploaded) {
            return;
        }

        this._.Messages.removeMessage(subid);
        deleteFile(`queue/${zipName}`);
        deleteFile(`encrypts/${paramName}`);
    }

    async verifyUniqueIds(subid, txid) {
        const exists = await this._.API.findID(subid);
        if (exists?.status != 200) {
            return true; //submission id does not already exist, unique: true
        }

        const existing = await this._.API.getLastTransactionID(subid);
        if (existing?.txid == txid) {
            return false; //id is unique, but the tx is already uploaded. unique: false
        }
        if (this.acceptedStatus(existing.status)) {
            return false; //tx is different, but last transaction was "good", so don't upload
        }

        return true; //tx is different and last payment status was "bad"
    }

    async verifyEzipAndParams(filename, paramname) {
        //valid, zip, params, zipFile, paramFile
        const ezip = await getFile(`queue/${filename}`);
        const params = await getFile(`encrypts/${paramname}`, true);

        if (!ezip?.data?.length || !params?.data?.length) {
            return {
                valid: false,
            };
        }

        return {
            valid: true,
            ezip: ezip.data,
            params: params.data,
        };
    }

    checkLock(id) {
        return this.uploading[id];
    }

    addLock(id) {
        if (this.checkLock(id)) {
            return;
        }

        this.uploading[id] = true;
    }

    removeLock(id) {
        delete this.uploading[id];
    }
}
