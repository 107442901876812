import axios from 'axios';
import ProductImageManager from './form/productImageManager';
import { getFileStats, downloadFileTo, deleteFile } from './filesystem';
import { getItem, setItem, deleteItem, listItems } from '../storage/local';

export default class Spark_API {
    constructor(p) {
        this.APIURL = '';
        this.AssetsURL = '';
        this.Client = '';
        this._ = p;
        this.AuthIssues = 0;
    }

    _a() {
        return this._.store.state.profile.token;
    }

    _auth() {
        return 'Basic ' + this._a();
    }

    _checkMetaTiming() {
        const now = Date.now();
        const last = this._.store.getters['profile/getTimeOf']('APIMetaTime');
        if (!last || now - last > 120000) {
            this._.store.commit('profile/setTimeOf', 'APIMetaTime');
            return true;
        }

        return false;
    }

    _prepareMetaInfo() {
        const deviceInfo = this._.store.getters['config/deviceInfo'];

        const info = {
            device: deviceInfo.device,
            browser: deviceInfo.browser,
            browser_version: deviceInfo.browser_version,
            os: deviceInfo.os,
            os_version: deviceInfo.os_version,
            app: 'Spark',
            app_version: this._.gv.version,
        };

        const lastLocation = this._.Location.getMostRecentPosition();
        if (lastLocation) {
            info.lat = lastLocation.lat;
            info.long = lastLocation.long;
        }

        return JSON.stringify(info);
    }

    _prepareLocation() {
        //never gets info right away, prepare for next time instead...
        const location = this._.store.getters['profile/getPositionServiceResponse'];

        const now = Date.now();
        const last = this._.store.getters['profile/getTimeOf']('APILocTime');
        if (!last || now - last > 1800000) {
            this._.store.commit('profile/setTimeOf', 'APILocTime');

            axios
                .get(this._.gv.locationCheck)
                .then((r) => {
                    this._.store.commit('profile/savePositionServiceResponse', r.data);
                })
                .catch(() => {});
        }

        return location ? JSON.stringify(location) : false;
    }

    _prepareMetaHeaders(settings) {
        if (this._checkMetaTiming()) {
            settings.headers['x-onboard-meta'] = this._prepareMetaInfo();

            const location = this._prepareLocation();
            if (location) {
                settings.headers['x-onboard-geoloc'] = location;
            }
        }
    }

    async _getFromStorage(uri) {
        let entry = false;
        try {
            entry = await getItem(uri);
        } catch (e) {
            //
        }

        return entry;
    }

    async _saveToStorage(uri, entry) {
        await setItem(uri, {
            lm: new Date().toISOString().replace(/\.[0-9]*Z$/, ''),
            data: entry,
        });
    }

    async _deleteFromStorage(uri) {
        await deleteItem(uri);
    }

    setAPIURL(id, url, assetUrl, tag) {
        this.Client = id;
        this._.Analytics.SetClient(id);

        if (tag) {
            this.APIURL = url.replace('https://', `https://${id}-${tag}---`);
            this.AssetsURL = assetUrl.replace('https://', `https://${id}-${tag}---`);
        } else {
            this.APIURL = url;
            this.AssetsURL = assetUrl;
        }

        if (!this.AssetsURL) {
            this.AssetsURL = this.APIURL;
        }
    }

    async login(username, password) {
        return axios(`${this.APIURL}/users/auth/`, {
            method: 'post',
            timeout: 30000,
            data: {
                username,
                password,
            },
        })
            .then((r) => {
                return {
                    success: true,
                    response: r.data,
                };
            })
            .catch((e) => {
                return {
                    success: false,
                    response: e?.response?.headers['x-onboard-auth-error'] || e?.message,
                };
            });
    }

    async reset(username) {
        return axios(`${this.APIURL}/users/password-reset/`, {
            method: 'post',
            timeout: 30000,
            data: {
                username,
            },
        })
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    async submitTwoFactor(token, code) {
        const header = 'Basic ' + token;
        return axios
            .post(`${this.APIURL}/users/2fa/`, { code }, { headers: { Authorization: header } })
            .then((r) => {
                return {
                    success: true,
                    response: r.data,
                };
            })
            .catch((e) => {
                return {
                    success: false,
                    response: e?.response?.headers['x-onboard-auth-error'] || e?.message,
                };
            });
    }

    async fetchPreferences() {
        const { preferences } = this._.store.state.profile;
        if (Object.keys(preferences).length) {
            //don't bother fetching if we already have a copy. We don't need it.
            return preferences;
        }

        const { player } = this._.store.state.profile;
        const path = `assets/players/${player}/preference.json`;

        return axios(`${this.AssetsURL}/${path}`, {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        })
            .then((r) => {
                this._.store.commit('profile/setPreferences', r.data);

                return r.data;
            })
            .catch(() => {
                return {};
            });
    }

    async savePreferences(key, value) {
        const { preferences } = this._.store.state.profile;
        if (!preferences.spark) {
            preferences.spark = {};
        }

        preferences.spark[key] = value;

        return await this.setPlayerPreferences(preferences);
    }

    async fetch(url, method, headers, data) {
        const settings = {
            method,
            timeout: 30000,
            headers,
            data,
        };

        return axios(url, settings)
            .then((r) => {
                return r.data;
            })
            .catch(() => {
                return false;
            });
    }

    async post(uri, body, options = {}) {
        const settings = {
            method: 'post',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        if (options?.timeout) {
            settings.timeout = options.timeout;
        }
        if (options?.headers) {
            settings.headers = {
                ...settings.headers,
                ...options.headers,
            };
        }

        if (Blob && body instanceof Blob) {
            let formData = new FormData();
            formData.append('file', body, options?.filename ?? 'file');
            settings.data = formData;
        } else {
            settings.data = body;
        }

        const url = uri.startsWith('assets/') ? this.AssetsURL : this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                this.clearAssetsFor(uri);
                return r.data || true;
            })
            .catch((e) => {
                console.warn(`failed to save ${uri}, ${e}`);
                if (options.retries && (options.num_retries || 0) < options.retries) {
                    options.num_retries = options.num_retries ? options.num_retries + 1 : 1;

                    return this.post(uri, body, options);
                }
                return false;
            });
    }

    async put(uri, body) {
        const settings = {
            method: 'put',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        if (Blob && body instanceof Blob) {
            let formData = new FormData();
            formData.append('file', body, 'file');
            settings.data = formData;
        } else {
            settings.data = body;
        }

        const url = uri.startsWith('assets/') ? this.AssetsURL : this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                this.clearAssetsFor(uri);
                return r.data || true;
            })
            .catch((e) => {
                console.warn(`failed to save ${url}, ${e}`);
                return false;
            });
    }

    async head(uri, lastModified) {
        const settings = {
            method: 'head',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        if (lastModified) {
            settings.headers['If-Modified-Since'] = lastModified;
        }

        const url = uri.startsWith('assets/') ? this.AssetsURL : this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return {
                    'status': 200,
                    'length': r.headers['content-length'],
                    'last-modified': r.headers['last-modified'],
                };
            })
            .catch((e) => {
                return {
                    status: e.response?.status,
                };
            });
    }

    async get(uri, preferCache) {
        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        const existingItem = await this._getFromStorage(uri);
        if (existingItem) {
            settings.headers['If-Modified-Since'] = existingItem.lm;

            if (preferCache) {
                return { data: existingItem.data };
            }
        }
        if (
            uri.endsWith('.png') ||
            uri.endsWith('.gif') ||
            uri.endsWith('.jpg') ||
            uri.endsWith('.jpeg') ||
            uri.endsWith('.pdf')
        ) {
            settings.responseType = 'arraybuffer';
        }

        const url = uri.startsWith('assets/') ? this.AssetsURL : this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then(async (r) => {
                if (settings.responseType == 'arraybuffer') {
                    r.data = new Blob([r.data], {
                        type: r.headers['content-type'],
                    });
                }
                if (Array.isArray(r.data) && existingItem) {
                    //merge a list of entities

                    if (!uri.startsWith('assets/')) {
                        const existingKeys = existingItem.data.map((c) => {
                            return c.id;
                        });

                        let allGood = true;
                        r.data.forEach((c, i) => {
                            const ind = existingKeys.indexOf(c.id);
                            if (ind > -1) {
                                if (Object.keys(c).length == 1) {
                                    //if new version is only the ID, keep old copy
                                    r.data[i] = existingItem.data[ind];
                                } else {
                                    //remove local asset copies of things...
                                    this.clearAssetsFor(c.id);
                                }
                            } else if (Object.keys(c).length == 1) {
                                //we still only have the id? something isn't quite right
                                //fetch a fresh list without if-modified-since
                                allGood = false;
                            }
                        });

                        if (!allGood) {
                            await this._deleteFromStorage(uri);
                            return await this.get(uri);
                        }
                    }
                }

                this._saveToStorage(uri, r.data);
                return { data: r.data, status: r?.status };
            })
            .catch((e) => {
                if (e?.response?.status == 401) {
                    if (++this.AuthIssues >= 3) {
                        this._.logout();
                    }
                }

                if (existingItem?.data) {
                    return { data: existingItem.data, status: e?.response?.status };
                }
                return { data: false, status: e?.response?.status };
            });
    }

    //maxage in seconds
    async getProductsFromURL(id, maxage = 150) {
        const uri = `products/${id}/`;
        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        const existingItem = await this._getFromStorage(uri);
        if (existingItem) {
            let lm = new Date(existingItem.lm);
            lm.setSeconds(lm.getSeconds() + maxage);
            if (lm > new Date() && existingItem.data) {
                return existingItem.data;
            }
        }

        const url = this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                this._saveToStorage(uri, r.data);
                return r.data;
            })
            .catch(() => {
                if (existingItem?.data) {
                    return existingItem.data;
                }
                return false;
            });
    }

    async getProductsWithFilters(id, filters) {
        const uri = `products/${id}/?${filters.join('&')}`;
        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        const url = this.APIURL;
        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return r.data;
            })
            .catch(() => {
                return false;
            });
    }

    async externalService(path, campaign, parameters, body, timeout) {
        let uri = `extsvc/${path}/?Campaign=${campaign}`;
        if (parameters) {
            uri += parameters.join('&');
        }

        return await this.post(uri, body, { timeout });
    }

    async accounts(opts, headers) {
        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        if (!opts) {
            return;
        }

        if (headers) {
            for (let j in headers) {
                settings.headers[j] = headers[j];
            }
        }

        let params = [];
        for (let j in opts) {
            params.push(j + '=' + encodeURIComponent(opts[j].toString()));
        }

        let uri = 'accounts/?' + params.join('&');

        return axios(`${this.APIURL}/${uri}`, settings)
            .then((r) => {
                return r.data;
            })
            .catch((e) => {
                console.warn('Error fetch accounts', e);
                return {
                    Count: 0,
                    Accounts: [],
                };
            });
    }

    async getAccountState(id) {
        const result = await this.accounts({
            ID: id,
            Fields: 'ID,Created,Campaign,Player,Team,Amount,PaymentStatus,TransactionErrorReason',
        });
        if (!result?.Count) {
            return false;
        }

        return result.Accounts[0];
    }

    async getLastTransactionID(id) {
        const result = await this.accounts({
            ID: id,
            Fields: 'ID,TransactionID,PaymentStatus',
        });

        if (!result?.Count) {
            return false;
        }

        return {
            status: result.Accounts[0].PaymentStatus,
            txid: result.Accounts[0].TransactionID,
        };
    }

    async streamLineAccounts(fields, filters, limit) {
        let headers = false;
        let fromTime = this._.Helpers.getSubmissionStartDate(limit);
        const campaign = filters.Campaign || 'global';

        let lastFetchedDate = this._.store.getters['submissions/getLastFetched'](campaign);
        const previousLargestRange = this._.store.getters['submissions/getLargestRange'](campaign);

        let afterDate = fromTime;
        if (previousLargestRange > fromTime) {
            //if our previous range was newer, then we're a larger range, and should fetch more
            lastFetchedDate = false;
        }

        if (lastFetchedDate) {
            //if we have fetched in the past, only fetch difference
            headers = {
                'if-modified-since': lastFetchedDate.toISOString(),
            };
            afterDate = lastFetchedDate;
        }

        let opts = {
            PageSize: 1000,
            Page: 0,
            Fields: (fields || []).join(','),
            After: afterDate.toISOString(), //don't re-fetch accounts that are unlikely to have changed
            ...filters,
        };
        let total = 0;
        let fetched = 0;

        do {
            //fetch accounts in groups of 1k to prevent too long of a stick
            opts.Page++;
            //last Fetched should have a 10m buffer to account for post processing changes
            const newLastFetched = new Date();
            newLastFetched.setMinutes(newLastFetched.getMinutes() - 10);

            const result = await this.accounts(opts, headers);
            this._.store.commit('submissions/setLastFetched', {
                //keep our request time for future requests
                lastFetched: newLastFetched,
                campaign: filters.Campaign || 'global',
            });
            this._.store.commit('submissions/setLargestRange', {
                //and keep track of what range we have fetched
                largestRange: fromTime,
                campaign: filters.Campaign || 'global',
            });

            if (result?.Accounts) {
                //if we actually had results, make sure to keep em
                await this._.store.commit('submissions/addMany', result.Accounts);
            }

            total = result?.Count || 0;
            fetched += result?.Accounts?.length || 0;
        } while (fetched < total);
    }

    async clearAssetsFor(id) {
        if (!id) {
            return;
        }

        const items = await listItems({ search: id });
        return await Promise.all(
            items.map((c) => {
                return deleteItem(c);
            })
        );
    }

    async getPlayer(player) {
        return await this.get(`players/${player}/`);
    }

    async getTeam(team) {
        return await this.get(`teams/${team}/`);
    }

    async getCampaign(campaign) {
        return await this.get(`campaigns/${campaign}/?include=general,voip`);
    }

    async getCampaignList(player) {
        return await this.get(`players/${player}/campaigns/`);
    }

    async getCampaignAssetList(campaign) {
        return await this.get(`assets/campaigns/${campaign}/`);
    }

    async getCampaignAsset(campaign, path, preferCache) {
        return await this.get(`assets/campaigns/${campaign}/${path}`, preferCache);
    }

    async headCampaignAsset(campaign, path, lastModified) {
        return await this.head(`assets/campaigns/${campaign}/${path}`, lastModified);
    }

    async getCampaignQRCode(campaign, preferCache) {
        return await this.get(`campaigns/${campaign}/qr`, preferCache);
    }

    async getFormAvgTime(campaign, preferCache) {
        return await this.get(`formtime/${campaign}`, preferCache);
    }

    async sendFormTime(campaign, timeInSeconds) {
        return await this.post(`formtime/${campaign}/`, {
            total_time: timeInSeconds,
        });
    }

    async sendFeedback(feedback) {
        return await this.post(`feedback/`, {
            text: feedback,
        });
    }

    async logout() {
        if (this._a()) {
            return await this.post(`users/logout/`);
        }
    }

    /*collaterals should be stored in long term storage, since they tend to be larger...
    should return a URL to the collateral, instead of a blob or anything*/
    async getCampaignCollateralURL(campaign, path) {
        const url = `${this.AssetsURL}/assets/campaigns/${campaign}/${path}`;
        if (this._.platform() == 'web') {
            //if on web, just show it online, no localstorage
            return `${url}?auth=${this._a()}`;
        } else {
            let filePath = `collaterals/${campaign}${path
                .replace('collaterals', '')
                .replace(/\//g, '_')}`;
            let lastModified = false;

            const existingFile = await getFileStats(filePath); //check if file is already downloaded
            if (existingFile?.mtime) {
                lastModified = new Date(parseInt(existingFile?.mtime)).toISOString();
            }

            const headRequest = await this.headCampaignAsset(campaign, path, lastModified); //check if file has changed

            if (headRequest?.status == 304) {
                //if it has not, use local copy
                return existingFile.uri;
            } else if (existingFile && headRequest.status == 200) {
                //if it has, delete local copy
                await deleteFile(filePath);
            }

            return await downloadFileTo(
                url,
                {
                    Authorization: this._auth(),
                },
                filePath
            );
        }
    }

    async getProductImage(campaign, url) {
        const promise = new Promise((resolve) => {
            ProductImageManager.load(
                {
                    campaign,
                    url,
                    platform: this._.platform(),
                },
                (localURL) => {
                    resolve(localURL);
                }
            );
        });

        return promise;
    }

    async cleanProductImages() {
        return ProductImageManager.cleanUp();
    }

    async getMyself() {
        const { data: me } = await this.getPlayer(this._.store.state.profile.player);
        if (me && me?.id) {
            //save me to storage for future usage
            this._.store.commit('profile/setMyself', me);
        }

        return me;
    }

    async getMyTeam() {
        const { data: myTeam } = await this.getTeam(this._.store.state.profile.team);
        if (myTeam && myTeam?.id) {
            this._.store.commit('profile/setMyTeam', myTeam);
        }

        return myTeam;
    }

    async getMyTeamExclusions(campaign, minutes = 30) {
        const myTeam = this._.store.state.profile.team;
        const lastFetched = this._.store.getters['profile/getExclusionLastFetched'];
        const now = Date.now();

        if (
            now - lastFetched > minutes * 60000 ||
            !this._.store.getters['profile/getExclusionsForCampaign'](campaign)
        ) {
            const { data: exclusionsList } = await this.get(
                `teamcampaignoptions/${myTeam}/`,
                false
            );
            const exclusionsMap = {};
            for (let j = 0; j < exclusionsList?.length; j++) {
                exclusionsMap[exclusionsList[j].campaign] = exclusionsList[j].options;
            }
            if (!exclusionsMap[campaign]) {
                exclusionsMap[campaign] = {};
            }
            this._.store.commit('profile/setExclusions', exclusionsMap);
        }
    }

    async getMyCampaigns() {
        const { data: list } = await this.getCampaignList(this._.store.state.profile.player);
        if (list.length) {
            this._.store.commit('profile/setCampaignList', list);
            for (let j = 0; j < list.length; j++) {
                this._.store.commit('campaigns/allowed', list[j].id);
            }
        }
        return list;
    }

    async getCampaignLogo(campaign, preferCache) {
        return await this.getCampaignAsset(campaign, 'campaign.png', preferCache);
    }

    async getPlayerPicture(player, preferCache) {
        return await this.get(`assets/players/${player}/player.png`, preferCache);
    }

    async getDashpages() {
        return await this.get(`dashpages/`, true);
    }

    async getLocationInformation(coordinates) {
        return await this.get(
            `validations/coords/?lat=${coordinates.latitude}&long=${coordinates.longitude}`
        );
    }

    async checkBIN(bin, service) {
        let params = '';
        if (service) {
            params = `?service=${service}`;
        }
        return await this.get(`validations/cc-bins/${bin}/${params}`);
    }

    async checkIBAN(country_code, check_digits, bban) {
        return await this.post(`validations/iban/`, {
            country_code,
            check_digits,
            bban,
        });
    }

    async getEmailValidation(email) {
        const uri = `validations/email/?email=${email}`;
        const url = this.APIURL;

        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return {
                    data: r.status == 200 ? true : false,
                    error: null,
                    status: r.status,
                };
            })
            .catch((e) => {
                return {
                    data: null,
                    error: e.response?.data || e.message,
                    status: e.request.status,
                };
            });
    }

    async getAddressValidation(address, campaign) {
        let uri = `validations/address/`;
        const url = this.APIURL;
        if (campaign) {
            uri += '?Campaign=' + campaign;
        }

        const settings = {
            method: 'post',
            timeout: 30000,
            data: address,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return { data: r.data, error: null, status: r.status };
            })
            .catch((e) => {
                return {
                    data: false,
                    error: e.response?.data || e.message,
                    status: e.request.status,
                };
            });
    }
    async searchAddresses(country, term) {
        const uri = `validations/address-search/`;
        const url = this.APIURL;

        const settings = {
            method: 'post',
            timeout: 30000,
            data: { term, country },
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return { data: r.data, error: null, status: r.status };
            })
            .catch((e) => {
                return {
                    data: false,
                    error: e.response?.data || e.message,
                    status: e.request.status,
                };
            });
    }

    async querySearchedAddressResult(id) {
        const uri = `validations/address-search/${id}/`;
        const url = this.APIURL;

        const settings = {
            method: 'get',
            timeout: 30000,
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return { data: r.data, error: null, status: r.status };
            })
            .catch((e) => {
                return {
                    data: false,
                    error: e.response?.data || e.message,
                    status: e.request.status,
                };
            });
    }

    async getUKBankValidation(accountNumber, sortCode) {
        const uri = `validations/uk-banks/`;
        const url = this.APIURL;

        const settings = {
            method: 'post',
            timeout: 30000,
            data: {
                sort_code: sortCode,
                account_number: accountNumber,
            },
            headers: {
                Authorization: this._auth(),
            },
        };
        this._prepareMetaHeaders(settings);

        return axios(`${url}/${uri}`, settings)
            .then((r) => {
                return { data: r.data, error: null, status: r.status };
            })
            .catch((e) => {
                return {
                    data: false,
                    error: e.response?.data || e.message,
                    status: e.request.status,
                };
            });
    }

    async getMyPicture(preferCache) {
        return await this.getPlayerPicture(this._.store.state.profile.player, preferCache);
    }

    async setPlayerPicture(player, picture) {
        return await this.post(`assets/players/${player}/player.png`, picture);
    }

    async setPlayerPreferences(asset) {
        const player = this._.store.state.profile.player;
        const blob = new Blob([JSON.stringify(asset)], {
            type: 'application/json',
        });

        return await this.post(`assets/players/${player}/preference.json`, blob);
    }

    async setMyPicture(picture) {
        return await this.setPlayerPicture(this._.store.state.profile.player, picture);
    }

    async saveCheckins(checkins) {
        return await this.put(`checkins/`, checkins);
    }

    async saveCancels(cancels) {
        return await this.put(`cancels/`, cancels);
    }

    async changePassword(currentPassword, password) {
        const id = this._.store.state.profile.player;
        return await this.post(`users/change-password/${id}`, {
            CurrentPassword: currentPassword,
            NewPassword: password,
        });
    }

    //chats are a subset of cancels
    async saveChats(chats) {
        return await this.saveCancels(chats);
    }

    async findID(id) {
        return await this.head(`ids/${id}/`);
    }

    async generateUUID(skipVerification, attempts = 0) {
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                let r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            })
            .substring(0, 8);

        if (skipVerification) {
            return uuid;
        }

        const result = await this.findID(uuid);
        if (result?.status != 200) {
            //we did not find a dup...
            return uuid;
        }

        if (++attempts < 5) {
            return await this.generateUUID(skipVerification, attempts);
        }
    }

    async getLocalBlob(url) {
        const settings = {
            method: 'get',
            timeout: 30000,
            responseType: 'arraybuffer',
        };
        return axios(url, settings)
            .then((r) => {
                if (settings.responseType == 'arraybuffer') {
                    r.data = new Blob([r.data], {
                        type: r.headers['content-type'],
                    });
                }

                return r.data;
            })
            .catch((e) => {
                console.log(`Could not get ${url}: ${e}`);
                return false;
            });
    }
}
