const _findAProductList = function(form) {
    if (form?.sections) {
        for (let j = 0; j < form?.sections?.length; j++) {
            const found = _findAProductList(form.sections[j]);
            if (found) {
                return found;
            }
        }
    } else if (form?.elements) {
        for (let j = 0; j < form.elements.length; j++) {
            if (form.elements[j].elements) {
                return _findAProductList(form.elements[j].elements);
            } else if (form.elements[j].type == 'products') {
                return form.elements[j].url ?? 'url';
            }
        }
    }

    return false;
};

export default class Spark_Campaigns {
    loading = [];
    isLoading = {
        a: false,
        b: false,
    };
    constructor(p) {
        this._ = p;

        if (this._.store.getters['config/isLowEndDevice']) {
            this.isLoading.b = true; //if on a lower end device, just load 1 campaign at a time
        }
    }

    async load(id) {
        this.loading.push(id);

        if (!this.isLoading.a) {
            await this.startQueue('a');
        } else if (!this.isLoading.b) {
            await this.startQueue('b');
        }
    }

    async startQueue(path) {
        const id = this.loading.shift();
        if (!id) {
            return;
        }

        this.isLoading[path] = true;
        await this.download(id, path);
    }

    async download(id, path) {
        let previouslyUpdated = this._.store.getters['campaigns/getOne'](id)?.updated;
        const { data: campaign, status } = await this._.API.getCampaign(id);
        const { data: assets } = await this._.API.getCampaignAssetList(id);

        if (status == 404 || status == 403) {
            this.isLoading[path] = false;
            await this._.store.commit('campaigns/forbidden', id);
            return;
        }

        if (!campaign || assets === null) {
            this.isLoading[path] = false;
            await this._.store.commit('campaigns/failedLoading', id);
            return;
        }

        await this._.store.commit('campaigns/addAssetList', {
            id,
            assets,
        });

        if (previouslyUpdated != campaign.updated) {
            //only overwrite campaign if it has changed
            await this._.store.commit('campaigns/addCampaign', campaign);
        }

        await this.loadMinimums(id, campaign.type, assets);

        //check if we have a products list that we should fetch a copy of
        await this.downloadProductList(id);

        await this._.store.commit('campaigns/finishedLoading', id);

        if (this.loading.length) {
            this.startQueue(path);
        } else {
            this.isLoading[path] = false;
        }
    }

    async downloadProductList(id) {
        const { data: form } = await this._.API.getCampaignAsset(id, 'form.json', true);
        const hasProductList = _findAProductList(form);

        if (hasProductList) {
            let type = 'url';
            if (hasProductList == 'products.json') {
                type = 'asset';
            }

            let result = false;
            //we need to fetch the product list, so we can keep it ready
            if (type == 'asset') {
                //simply fetch it and keep a copy, if it's changed, update it
                result = (await this._.API.getCampaignAsset(id, 'products.json'))?.data?.length;
            } else {
                //fetch the product list so we have it ready in case we need it
                result = (await this._.API.getProductsFromURL(id))?.length;
            }

            if (!result) {
                this._.store.commit('campaigns/missingProducts', id);
                this._.Analytics.Exception('campaign', 'missing_product_list', id);
            } else {
                this._.store.commit('campaigns/foundProducts', id);
            }
        }

        return true;
    }

    async downloadAsset(id, name, path, lastModified) {
        const existing = this._.store.getters['campaigns/getAssetItem'](id, name);

        if (existing?.lm != lastModified) {
            await this._.API.getCampaignAsset(id, path, false);
            await this._.store.commit('campaigns/addAsset', {
                id,
                name,
                path,
                lastModified,
            });
        }
    }

    async loadMinimums(id, type, assets) {
        switch (type) {
            case 'voip':
                return await this.loadMinimumForInVOIPCampaigns(id, assets);
            case 'self-serve':
                return await this.loadMinimumForSelfServeCampaigns(id, assets);
            case 'in-person':
            default:
                return await this.loadMinimumForInPersonCampaigns(id, assets);
        }
    }

    async loadMinimumForInPersonCampaigns(id, assets) {
        const minimums = {
            banner: /collaterals\/splash\.png/,
            logo: /campaign\.png/,
            form: /form\.json/,
            general: /settings\/general\.json/,
            territory: /settings\/territory\.json/,
            assets: /assets\//,
        };

        return await this.loadMinimumFiles(id, minimums, assets);
    }

    async loadMinimumForSelfServeCampaigns(id, assets) {
        const minimums = {
            banner: /collaterals\/splash\.png/,
            logo: /campaign\.png/,
            form: /form\.json/,
            general: /settings\/general\.json/,
            territory: /settings\/territory\.json/,
        };

        return await this.loadMinimumFiles(id, minimums, assets);
    }

    async loadMinimumForInVOIPCampaigns(id, assets) {
        const minimums = {
            banner: /collaterals\/splash\.png/,
            logo: /campaign\.png/,
            form: /form\.json/,
            general: /settings\/general\.json/,
            territory: /settings\/territory\.json/,
            assets: /assets\//,
        };

        return await this.loadMinimumFiles(id, minimums, assets);
    }

    async loadMinimumFiles(id, minimums, assets) {
        const skip = /banner\.png|banner_large\.png/;

        for (let j = 0; j < assets.length; j++) {
            if (skip.test(assets[j].File)) {
                continue;
            }

            for (let k in minimums) {
                if (minimums[k].test(assets[j].File)) {
                    if (k == 'assets') {
                        await this.downloadAsset(
                            id,
                            `${k}_${assets[j].File.replace(/[ /.]/g, '_')}`,
                            assets[j].File,
                            assets[j].LastModified
                        );
                    } else {
                        await this.downloadAsset(id, k, assets[j].File, assets[j].LastModified);
                    }
                    continue;
                }
            }
        }

        return true;
    }
}
