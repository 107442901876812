export default class Spark_Messages {
    constructor(p) {
        this._ = p;
    }

    levels = {
        ERROR: 'error',
        WARNING: 'warning',
        INFO: 'info',
        SUCCESS: 'success',
    };

    async addMessage(content) {
        const existingMessages = this._.store.getters['profile/getMessageByID'](content.id);
        if (existingMessages.length) {
            console.warn(`There is already a message in list with the same id (${content.id})`);
            return;
        }

        content.time = Date.now();
        this._.store.commit('profile/addMessage', content);
    }

    async removeMessage(id) {
        this._.store.commit('profile/removeMessage', id);
    }
}
