import { Geolocation } from '@capacitor/geolocation';

export default class Spark_Location {
    constructor(p) {
        this._ = p;
    }
    async verifyGeoPermission() {
        try {
            return await Geolocation.checkPermissions();
        } catch (e) {
            return 'denied';
        }
    }

    async requestGeoPermission() {
        let outcome = 'denied';

        if (this._.platform() == 'web') {
            try {
                const location = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: false,
                    timeout: 30000,
                    maximumAge: 300000,
                });
                if (location.coords) {
                    outcome = 'granted';
                }
            } catch (e) {
                if (e.code == 3) {
                    outcome = 'granted';
                } else if (e.code != 1) {
                    console.warn(
                        'Attempted to request geo permissiond on web, failed to get location...',
                        e
                    );
                }
            }
        } else {
            try {
                const request = await Geolocation.requestPermissions();
                outcome = request.location;
            } catch (e) {
                //
            }
        }

        return outcome;
    }

    getMostRecentPosition() {
        return this._.store.getters['profile/getPosition'];
    }

    async getCurrentPosition() {
        const position = await new Promise((resolve, reject) => {
            try {
                this.watchID = Geolocation.watchPosition(
                    {
                        enableHighAccuracy: true,
                        timeout: 30000,
                        maximumAge: 300000, //5m
                    },
                    async (r) => {
                        const wid = await this.watchID;
                        if (wid) {
                            Geolocation.clearWatch({
                                id: wid,
                            });
                        }
                        resolve(r);
                    }
                );
            } catch (e) {
                console.warn(`getCurrentPosition: ${e.message}`);
                reject(false);
            }
        });

        const wid = await this.watchID;
        if (wid) {
            Geolocation.clearWatch({ id: wid });
        }
        delete this.watchID;

        let res = {
            fetched: Date.now(),
        };

        if (position?.coords) {
            res.latitude = position.coords.latitude;
            res.longitude = position.coords.longitude;
            res.accuracy = position.coords.accuracy;

            this._.store.commit('profile/savePosition', res);

            return res;
        }

        return false;
    }

    //Pass a maxminutes to use cached result if not older than X minutes
    //helps avoid app feeling slow if location is not needed to be 100% accurate in low risk situations
    async getLocationForInteraction(maxminutes) {
        if ((await this.verifyGeoPermission()) != 'granted') {
            const requested = await this.requestGeoPermission();
            if (requested != 'granted') {
                return false;
            }
        }

        if (maxminutes) {
            const old = await this._.store.getters['profile/getPosition'];

            if (old?.fetched && (Date.now() - old?.fetched || 0) < maxminutes * 60 * 1000) {
                console.log(`existing location request is < ${maxminutes} old - use that instead`);
                return old;
            }
        }

        return await this.getCurrentPosition();
    }

    async getLocationInformation() {
        const coords = await this.getLocationForInteraction(5);
        if (!coords) {
            return false;
        }

        return (await this._.API.getLocationInformation(coords)).data;
    }

    checkPolygon(coord, polygon) {
        let last = polygon[polygon.length - 1]; //if the polygon isn't closed, close it
        if (polygon[0][0] != last[0] || polygon[0][1] != last[1]) {
            polygon.push(polygon[0]);
        }

        let isLeft = function(poly1, poly2, point) {
            return (
                (poly2[0] - poly1[0]) * (point.longitude - poly1[1]) -
                (point.latitude - poly1[0]) * (poly2[1] - poly1[1])
            );
        };

        let wn = 0;
        //polygon: 0 = lat, 1 = long
        for (let i = 0; i < polygon.length - 1; i++) {
            if (polygon[i][0] != '' && polygon[i][1] !== '') {
                if (polygon[i][1] <= coord.longitude) {
                    if (polygon[i + 1][1] > coord.longitude) {
                        if (isLeft(polygon[i], polygon[i + 1], coord) > 0) {
                            wn++;
                        }
                    }
                } else {
                    if (polygon[i + 1][1] <= coord.longitude) {
                        if (isLeft(polygon[i], polygon[i + 1], coord) < 0) {
                            wn--;
                        }
                    }
                }
            }
        }

        return wn;
    }

    validateLocation(coordinate, polygons) {
        let sum = 0;
        let atLeastOneAllow = false;
        for (let j in polygons) {
            if (!j.endsWith(':prevent')) {
                atLeastOneAllow = true;
            }

            let result = this.checkPolygon(coordinate, polygons[j]);
            if (result != 0 && j.endsWith(':prevent')) {
                return {
                    allowed: false,
                    key: j,
                    standing: 'inside',
                }; //if we matched a polygon, but we should not be inside it, we are BAD
            }

            sum += Math.abs(result);
        }

        if (!atLeastOneAllow) {
            //if we only have a list of prevents, and we don't match any, we must be ok
            return { allowed: true };
        }

        return { allowed: sum != 0 }; //!= 0: inside at least 1 polygon, 0 = outside all polygons
    }

    getDistanceToLine(coordinate, linePointA, linePointB) {
        let x, y, x1, x2, y1, y2;
        x = coordinate.latitude;
        y = coordinate.longitude;
        x1 = linePointA[0];
        y1 = linePointA[1];
        x2 = linePointB[0];
        y2 = linePointB[1];

        let A = x - x1;
        let B = y - y1;
        let C = x2 - x1;
        let D = y2 - y1;

        let dot = A * C + B * D;
        let len_sq = C * C + D * D;
        let param = -1;
        if (len_sq != 0) {
            param = dot / len_sq;
        }

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        let dx = x - xx;
        let dy = y - yy;

        return Math.sqrt(dx * dx + dy * dy);
    }

    getDistanceToPolygon(coordinate, polygon) {
        let closestDistance = Number.MAX_SAFE_INTEGER;
        for (let j = 1; j < polygon.length; j++) {
            let thisDistance = this.getDistanceToLine(coordinate, polygon[j - 1], polygon[j]);
            if (thisDistance < closestDistance) {
                closestDistance = thisDistance;
            }
        }

        return closestDistance;
    }

    getClosestPolygon(coordinate, polygons) {
        let closestInMeters = Number.MAX_SAFE_INTEGER;
        let closestKey = '';

        for (let j in polygons) {
            if (j.endsWith(':prevent')) {
                continue;
            }

            let thisDistance = this.getDistanceToPolygon(coordinate, polygons[j]);
            if (thisDistance < closestInMeters) {
                closestInMeters = thisDistance;
                closestKey = j;
            }
        }

        if (closestKey) {
            return polygons[closestKey];
        }

        return false;
    }

    generateNearestPolygonMapLink(location, polygon, color) {
        let link = 'https://maps.googleapis.com/maps/api/staticmap?size=400x400';
        let colorCode = this._.gv.accentColor;
        if (color == 'red') {
            colorCode = 'e84b4b';
        }
        if (colorCode.indexOf('#') > -1) {
            colorCode = colorCode.replace('#', '');
        }

        link += '&key=' + this._.store.getters['config/configurationValue']('maps_api_key');
        link += `&markers=${location.latitude},${location.longitude}`;
        link +=
            `&path=color:0x${colorCode}|fillcolor:0x${colorCode}|` +
            polygon.map((c) => c.join(',')).join('|');

        return link;
    }
}
