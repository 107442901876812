import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

export default class Spark_Analytics {
    constructor(p) {
        this._ = p;

        this.startTrackingUncaughtExceptions();
    }

    SetClient(client) {
        this.client = client;

        if (this._.platform() != 'web') {
            try {
                FirebaseCrashlytics.setContext({
                    key: 'Client',
                    type: 'string',
                    value: client,
                });
            } catch (e) {
                console.warn(e);
            }
        }
    }

    SetUser(u) {
        this.user = u;

        if (this._.platform() != 'web') {
            try {
                FirebaseCrashlytics.setContext({
                    key: 'User',
                    type: 'string',
                    value: u,
                });
            } catch (e) {
                console.warn(e);
            }
        }
    }

    SetDeviceInfo(info) {
        this.platform = info.os + ':' + info.os_version;
        this.appName = this._.gv.name;
        this.appVersion = this._.gv.version;
        this.device = info.device;
    }

    _log(e) {
        e.o = this.client;
        e.p = this.platform;
        e.an = this.appName;
        e.av = this.appVersion;
        e.u = this.user;
        e.d = this.device;
        e.t = Date.now();

        const event = JSON.stringify(e);
        console.log('$log', event);
        if (window.location.hostname == 'localhost' && this._.platform() == 'web') {
            return;
        }

        try {
            const req = new XMLHttpRequest();
            req.open('post', this._.gv.logging);
            req.timeout = 10000;
            req.send(event);
        } catch (e) {
            console.warn('Failed to send event to server');
        }
    }

    PageView(name) {
        if (!name || name == '/') {
            return;
        }

        this._log({
            c: 'page',
            l: name,
        });
    }

    //Action, Label, Value
    Action(action, label, value) {
        this._log({
            c: 'action',
            a: action,
            l: label,
            v: value,
        });
    }

    Event(action, label, value) {
        this._log({
            c: 'event',
            a: action,
            l: label,
            v: value,
        });
    }

    Exception(action, label, value) {
        this._log({
            c: 'exception',
            a: action,
            l: label,
            v: value,
        });
    }

    Info(action, label, value) {
        this._log({
            c: 'info',
            a: action,
            l: label,
            v: value,
        });
    }

    startTrackingUncaughtExceptions() {
        window.addEventListener('error', (e, u, l, c) => {
            return this.TrackException(e, u, l, c);
        });

        window.addEventListener('unhandledrejection', (e) => {
            return this.TrackException(e);
        });
    }

    TrackException(errorMessage, url, lineNumber, colNumber) {
        try {
            let message = '';
            if (typeof errorMessage == 'string') {
                //old fashioned message
                message = `${url}@${lineNumber}-${colNumber}: ${message}`;
            } else if (errorMessage?.message) {
                //unhandled exception
                message = `${errorMessage.filename}@${errorMessage.lineno}-${errorMessage.colno}: ${errorMessage.message}`;
            } else if (errorMessage?.reason) {
                //unhandled promise rejection
                message = `${errorMessage.reason?.stack || errorMessage.reason}`;
            }

            this.Exception('uncaught', message);
            if (this._.platform() != 'web') {
                FirebaseCrashlytics.recordException({
                    message,
                });
            }
        } catch (e) {
            console.log(e);
            //
        }

        return false; //ensure normal exception bubbles up properly
    }
}
