import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

const _getCheckinFile = async function() {
    let opts = {
        path: 'checkins.json',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
    };
    let file = [];

    try {
        const res = await Filesystem.readFile(opts);
        file = JSON.parse(res?.data) || [];
    } catch (e) {
        console.log('Missing checkin file', e);
    }

    return file;
};

const _saveCheckinFile = async function(content) {
    try {
        await Filesystem.writeFile({
            path: 'checkins.json',
            data: content,
            directory: Directory.Data,
            encoding: Encoding.UTF8,
            recursive: true,
        });
    } catch (e) {
        console.warn('Failed to save checkin file', e);
        return false;
    }

    return true;
};

const _deleteCheckinFile = async function() {
    try {
        await Filesystem.deleteFile({
            path: 'checkins.json',
            directory: Directory.Data,
        });
    } catch (e) {
        console.warn('Failed to delete checkin file', e);
    }
};

export default class Spark_Checkins {
    constructor(p) {
        this._ = p;
    }

    async save(item) {
        let file = await _getCheckinFile();
        file.filter((c) => {
            return c.ID != item.ID;
        });
        file.push(item);

        file = JSON.stringify(file);

        await _saveCheckinFile(file);

        if (await this._.API.saveCheckins(file)) {
            await _deleteCheckinFile();
        } else {
            console.log('Must be offline...');
        }
    }

    async delete(campaign, reason, id) {
        const checkin = this._.store.getters['profile/myCheckin'](campaign);
        if (!id) {
            id = checkin?.ID || '';
        }
        this._.store.commit('profile/deleteCheckin', campaign);

        if (!id) {
            return;
        }

        let interacted = new Date();
        if (checkin) {
            const maxInteracted = new Date(checkin.Expires);
            if (interacted > maxInteracted) {
                interacted = maxInteracted;
            }
        }

        await this.save({
            ID: id,
            Interacted: interacted.toISOString(),
            Status: reason,
        });
    }

    async update(campaign, id) {
        const checkin = this._.store.getters['profile/myCheckin'](campaign);
        if (!id) {
            id = checkin?.ID || '';
        }
        if (!id) {
            return;
        }

        let interacted = new Date();
        if (checkin) {
            const maxInteracted = new Date(checkin.Expires);
            if (interacted > maxInteracted) {
                interacted = maxInteracted;
            }
        }

        await this.save({
            ID: id,
            Interacted: interacted,
            Status: 'using',
        });
    }

    calculateTimeLeft(id) {
        const checkin = this._.store.getters['profile/myCheckin'](id);
        if (!checkin) {
            return 0;
        }

        const expires = new Date(checkin.Expires).valueOf();
        const msLeft = Math.floor((expires - Date.now()) / 1000);

        return msLeft;
    }

    isValidCheckin(id) {
        const checkin = this._.store.getters['profile/myCheckin'](id);
        if (!checkin) {
            return false;
        }

        if (new Date(checkin.Expires).valueOf() < Date.now()) {
            this.delete(id, 'expired', checkin.ID);
            return false;
        }

        return true;
    }
}
