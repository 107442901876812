function parseToken(token) {
    const split = token.split('.');
    if (split.length != 3) {
        return false;
    }

    let terms = false;
    try {
        terms = JSON.parse(atob(split[1]));
    } catch (e) {
        console.error('Failed to parse token');
    }

    return terms;
}

export const profileModule = {
    state: () => ({
        player: '',
        team: '',
        token: '',
        packageKey: '',
        contentKey: '',
        loginDay: 0,
        hasSeenDailyMessage: false,
        campaigns: [],
        exclusions: {},
        preferences: {},
        myself: {},
        myTeam: {},
        checkins: {},
        chats: {},
        position: false,
        positionService: false,
        timers: {},
        messages: [],
        lastPage: '/',
    }),
    namespaced: true,
    mutations: {
        initialize: (state, s) => {
            //get rid of anything we shouldn't have
            for (let j in s) {
                state[j] = s[j];
            }
        },
        setCampaignList: (state, campaigns) => {
            state.campaigns = (campaigns || []).map((c) => {
                return {
                    id: c.id,
                    type: c.type,
                    title: c.title,
                    acronym: c.acronym,
                    country: c.country,
                    description: c.description,
                };
            });
        },
        setExclusions: (state, exclusions) => {
            state.exclusions = {
                fetched: Date.now(),
                ...exclusions,
            };
        },
        setPreferences: (state, preferences) => {
            state.preferences = preferences;
        },
        setMyself: (state, myself) => {
            state.myself = myself;
        },
        setMyTeam: (state, myTeam) => {
            state.myTeam = myTeam;
        },
        setLoginTime: (state) => {
            state.loginDay = new Date().getDate(); //save the current day of month
        },
        setMyLastUpdateTime: (state) => {
            state.myself.updated = new Date().toISOString();
        },
        sawWelcomeMessage: (state) => {
            state.hasSeenDailyMessage = true;
        },
        addCheckin: (state, checkin) => {
            state.checkins[checkin.Campaign] = checkin;
        },
        deleteCheckin: (state, campaign) => {
            delete state.checkins[campaign];
        },
        addChat: (state, id) => {
            if (!state.chats[id]) {
                state.chats[id] = 0;
            }
            state.chats[id]++;
        },
        addMessage: (state, message) => {
            state.messages.push(message);
        },
        removeMessage: (state, messageid) => {
            state.messages = state.messages.filter((a) => {
                return a.id != messageid;
            });
        },
        savePosition: (state, position) => {
            state.position = position;
        },
        savePositionServiceResponse: (state, response) => {
            state.positionService = response;
        },
        setTimeOf: (state, name) => {
            state.timers[name] = Date.now();
        },
        lastPage: (state, page) => {
            state.lastPage = page;
        },
    },
    getters: {
        getState: (state) => {
            //can filter and exclude certain fields if we don't want to save their state
            return JSON.stringify(state);
        },
        myName: (state) => {
            return state.myself?.firstname + ' ' + state.myself?.lastname;
        },
        myUsername: (state) => {
            return state.myself?.username;
        },
        myTeamName: (state) => {
            return state.myTeam?.title;
        },
        hasSeenWelcomeMessage: (state) => {
            return state.hasSeenDailyMessage;
        },
        welcomeMessages: (state) => {
            return state.myTeam?.welcomes;
        },
        myEmail: (state) => {
            return state.myself?.email;
        },
        myPhone: (state) => {
            return state.myself?.phone;
        },
        myCreationDate: (state) => {
            return state.myself?.created;
        },
        myLastUpdatedDate: (state) => {
            return state.myself?.updated;
        },
        myCheckin: (state) => (id) => {
            return state.checkins?.[id];
        },
        getChatCount: (state) => (campaign) => {
            return state.chats?.[campaign] ?? 0;
        },
        popularCompaigns: (state) => {
            return state.campaigns.sort((a, b) => {
                return (a.title || '').localeCompare(b.title || '');
            });
        },
        getACampaignName: (state) => (id) => {
            for (let j = 0; j < state.campaigns.length; j++) {
                if (state.campaigns[j].id == id) {
                    return state.campaigns[j].title;
                }
            }

            return '';
        },
        getACampaignDescription: (state) => (id) => {
            for (let j = 0; j < state.campaigns.length; j++) {
                if (state.campaigns[j].id == id) {
                    return state.campaigns[j].description;
                }
            }

            return '';
        },
        getExclusionsForCampaign: (state) => (id) => {
            return state.exclusions[id] || false;
        },
        getExclusionLastFetched: (state) => {
            return state.exclusions?.fetched || 0;
        },
        getTimeOf: (state) => (name) => {
            return state.timers?.[name];
        },
        getPosition: (state) => {
            return state.position;
        },
        getPositionServiceResponse: (state) => {
            return state.positionService;
        },
        getPackageKey: (state) => {
            return state.packageKey;
        },
        getContentKey: (state) => {
            return state.contentKey;
        },
        getMessageByID: (state) => (id) => {
            return state.messages.filter((a) => {
                return a.id == id;
            });
        },
        getMessages: (state) => {
            return state.messages;
        },
        getMyLastPage: (state) => {
            return state.lastPage;
        },
        hasMessages: (state) => {
            return state.messages.length;
        },
        getTokenValidity: (state) => {
            if (!state.token) {
                return false;
            }

            //also ensure same day restriction is verified
            if (state.loginDay != new Date().getDate()) {
                return false;
            }

            const tokenInfo = parseToken(state.token);
            return tokenInfo;
        },
        getTeamMeta: (state) => (key) => {
            const metadata = state?.myTeam?.metadata;
            if (metadata?.save?.[key] !== undefined) {
                return metadata.save[key];
            } else if (metadata?.other?.[key] !== undefined) {
                return metadata.other[key];
            }
            return '';
        },
        getPlayerMeta: (state) => (key) => {
            const metadata = state?.myself?.metadata;
            if (metadata?.save?.[key] !== undefined) {
                return metadata.save[key];
            } else if (metadata?.other?.[key] !== undefined) {
                return metadata.other[key];
            }
            return '';
        },
    },
    actions: {
        set({ state, commit }, profile) {
            state.contentKey = profile.ContentPubKey;
            state.packageKey = profile.PackagePubKey;
            state.token = profile.APIToken;

            const info = parseToken(state.token);
            if (info) {
                state.team = info.team;
                state.player = info.player;
            }

            commit('setExclusions', profile.ExclusionsByCampaign || {});
            commit('setCampaignList', profile.Campaigns);
        },
    },
};
