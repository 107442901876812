<script>
import { SplashScreen } from 'mm-capacitor-splash-screen';
import { Device } from '@capacitor/device';
import { Toast } from '@capacitor/toast';
import { App as CapacitorApp } from '@capacitor/app';

export default {
    name: 'Spark',
    data() {
        return {
            backTwiceToExit: false,
            sameDayLoop: 0,
        };
    },
    computed: {
        tag() {
            return this.$store.state.config.tag;
        },
    },
    methods: {
        backButton() {
            //first check if we have any spark specific listeners we should call first...
            if (this.$spark.triggerBackListeners()) {
                return;
            }

            const lastPage = this.$router.currentRoute.value.fullPath;

            this.$router.back();
            setTimeout(() => {
                if (this.$router.currentRoute.value.fullPath == lastPage) {
                    if (this.backTwiceToExit) {
                        CapacitorApp.exitApp();
                        return;
                    }
                    this.backTwiceToExit = true;

                    Toast.show({
                        text: this.$t('android.tapToExit'),
                    });

                    setTimeout(() => {
                        this.backTwiceToExit = false;
                    }, 2000);
                }
            }, 250);
        },
        androidInsetDetection() {
            if (window.AndroidNotch) {
                const style = document.documentElement.style;

                // Apply insets as css variables
                window.AndroidNotch.getInsetTop(
                    (px) => {
                        style.setProperty('--notch-inset-top', px + 'px');
                    },
                    (err) => console.error('Failed to get insets top:', err)
                );

                window.AndroidNotch.getInsetRight(
                    (px) => {
                        style.setProperty('--notch-inset-right', px + 'px');
                    },
                    (err) => console.error('Failed to get insets right:', err)
                );

                window.AndroidNotch.getInsetBottom(
                    (px) => {
                        style.setProperty('--notch-inset-bottom', px + 'px');
                    },
                    (err) => console.error('Failed to get insets bottom:', err)
                );

                window.AndroidNotch.getInsetLeft(
                    (px) => {
                        style.setProperty('--notch-inset-left', px + 'px');
                    },
                    (err) => console.error('Failed to get insets left:', err)
                );
            }
        },
        restored(e) {
            console.log('Resumed (Android) with data:', e);
        },
    },
    mounted() {
        setTimeout(() => {
            SplashScreen.hide();
        }, 1000);
    },
    async created() {
        //setup the store, don't load page until store is ready
        await this.$store.dispatch('initialize');
        await this.$store.dispatch('config/loadConfig');
        await this.$store.dispatch('config/setLanguage');
        await this.$store.dispatch('completedInitalization');
        const isLowEnd = await this.$spark.checkLowEndDevice();
        this.$spark.checkGlobalPrivacyScreen();

        if (!this.$store.getters['config/deviceInfo']) {
            const id = await Device.getId();
            const info = await Device.getInfo();
            const browser = this.$spark.Helpers.getBrowserName();

            const toSave = {
                device: id.identifier.substring(0, 8),
                browser: browser,
                browser_version: info.webViewVersion,
                os: info.platform,
                os_version: info.osVersion,
            };

            this.$store.commit('config/saveDeviceInfo', toSave);
        }

        if (this.$spark.ensureAuth()) {
            this.$log.SetClient(this.$store.state.config.clientcode);
            this.$log.SetDeviceInfo(this.$store.getters['config/deviceInfo']);
            this.$log.SetUser(this.$store.state.profile.player);
        }

        //add in android back button functionality
        if (this.$spark.platform() === 'android') {
            CapacitorApp.addListener('backButton', this.backButton);

            CapacitorApp.addListener('appRestoredResult', this.restored);

            this.androidInsetDetection();
            window.addEventListener('resize', this.androidInsetDetection);
            window.addEventListener('orientationchange', this.androidInsetDetection);
        }

        //hack IOS - click away should lead to blur. Not on iOS. need to trigger it manually
        //do not call blur if the user is tapping on a select / input / textarea element
        if (this.$spark.platform() === 'ios') {
            document.addEventListener('click', (e) => {
                if (
                    document.activeElement &&
                    e.target.nodeName != 'INPUT' &&
                    e.target.nodeName != 'TEXTAREA' &&
                    e.target.nodeName != 'SELECT'
                ) {
                    document.activeElement.blur();
                }
            });
        }

        if (isLowEnd) {
            document.body.classList.add('low-end');
        }
        document.body.classList.add(this.$spark.platform());

        if (!this.$spark.Helpers.detectFlexGapSupport()) {
            document.body.classList.add('no-flex-gap');
        }
    },
};
</script>

<template>
    <div v-if="this.$store.state.initialized" class="load-guard">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition || 'fade'">
                <component :is="Component" />
            </transition>
        </router-view>
        <div v-if="tag" id="api-tag">Tag: {{ tag }}</div>
        <div id="popups"></div>
    </div>
</template>

<style lang="scss">
@import './scss/root.scss';

#popups {
    height: 0px !important;
}

#api-tag {
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 12px;
    font-weight: bold;
}
</style>
