import { registerPlugin } from '@capacitor/core';

import type { RamCheckPlugin } from './definitions';

const RamCheck = registerPlugin<RamCheckPlugin>('RamCheck', {
  web: () => import('./web').then(m => new m.RamCheckWeb()),
});

export * from './definitions';
export { RamCheck };
