console.log('Starting app...');
import { createApp } from 'vue';
import App from './App.vue';
//Create the Vue App
const app = createApp(App);

import { i18n } from './i18n';
//Add locatization support
app.use(i18n);

import { store } from './storage/store';
//Add vuex store, used for post login state storage, mostly cleared on login
//paired with localforage to store in indexeddb
app.use(store);

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
//Add SVG global Component
app.use(VueSvgInlinePlugin);

import { setupSpark } from './spark/main';
//Add Spark platform
const spark = setupSpark(store);
app.use(spark);
app.config.globalProperties.$log = spark.Analytics;

import { setupRouter } from './router';
//Add the vue router, passing localization & spark to allow for improved page support
app.use(setupRouter(i18n, spark));

app.mount('#app');
console.log('Mounted app...');

try {
    if (
        //use polyfill for intersection observer if not found
        !(
            'IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype
        )
    ) {
        require('intersection-observer');
    }
} catch (e) {
    console.warn('Could not polyfill intersectionobserver');
}

import { ObserveVisibility } from 'vue-observe-visibility';
app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
});

//test var for easy debug access
window.SPARK = spark;
