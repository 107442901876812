import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Http } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';

//utf8 is needed if we will be storing text. Storing b64 instead causes issues. JSON -> Lost part of value
export async function saveToStorage(key, entry, utf8) {
    let writeResult = false;
    try {
        writeResult = await Filesystem.writeFile({
            path: key,
            data: entry,
            directory: Directory.Data,
            recursive: true,
            encoding: utf8 ? Encoding.UTF8 : undefined,
        });
    } catch (e) {
        console.warn('saveToStorage', key, e);
    }

    return writeResult?.uri ?? false;
}

export async function deleteFile(key) {
    try {
        await Filesystem.deleteFile({
            path: key,
            directory: Directory.Data,
        });
    } catch (e) {
        console.warn('deleteFile', key, e);
        return false;
    }

    return true;
}

export async function createFolder(key) {
    try {
        await Filesystem.mkdir({
            path: key,
            directory: Directory.Data,
            recursive: true,
        });
    } catch (e) {
        console.warn('createFolder', key, e);
        return false;
    }

    return true;
}

//utf8 is needed if we will be storing text. Storing b64 instead causes issues. JSON -> Lost part of value
export async function getFile(key, utf8) {
    let file = false;
    try {
        file = await Filesystem.readFile({
            path: key,
            directory: Directory.Data,
            encoding: utf8 ? Encoding.UTF8 : undefined,
        });
    } catch (e) {
        console.log('getFile', key, e);
    }

    return file;
}

export async function getFileStats(key) {
    let stats = false;
    try {
        stats = await Filesystem.stat({
            path: key,
            directory: Directory.Data,
        });
    } catch (e) {
        console.log('getFileStats', key, e);
    }

    if (stats?.uri) {
        stats.uri = Capacitor.convertFileSrc(stats.uri);
    }

    return stats;
}

export async function getFileURI(key) {
    let res = false;
    try {
        res = await Filesystem.getUri({
            path: key,
            directory: Directory.Data,
        });
    } catch (e) {
        console.warn('getFileURI', key, e);
    }

    return Capacitor.convertFileSrc(res?.uri);
}

export function convertFileSrc(filePath) {
    return Capacitor.convertFileSrc(filePath);
}

export async function readDir(key) {
    let res = false;
    try {
        res = await Filesystem.readdir({
            path: key,
            directory: Directory.Data,
        });
    } catch (e) {
        console.warn('readdir', key, e);
    }

    return res;
}

export async function downloadFileTo(url, headers, filePath) {
    const folders = filePath.split('/');
    if (folders.length > 1) {
        //need to ensure all folders are created. otherwise android gives I/O ERROR
        await createFolder(filePath.substring(0, filePath.lastIndexOf('/')));
    }

    let downloadResult;
    let options = {
        url: encodeURI(url),
        filePath,
        fileDirectory: Directory.Data,
        method: 'GET',
    };
    if (headers) {
        options.headers = headers;
    }
    try {
        downloadResult = await Http.downloadFile(options);
    } catch (e) {
        console.warn('downloadFileTo', url, filePath, e);
    }

    if (downloadResult?.blob) {
        return URL.createObjectURL(downloadResult.blob);
    } else if (downloadResult?.path) {
        return Capacitor.convertFileSrc(downloadResult.path);
    }

    return false;
}
