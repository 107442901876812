import { getItem, setItem } from '../storage/local';
import { globalVariables } from '../global-vars';
import axios from 'axios';

async function loadCachedItem(key) {
    let item = null;
    try {
        item = await getItem(key);
    } catch (e) {
        console.warn('Failed to fetch item from storage');
    }

    return item;
}

async function loadItem(folder, key, localVersion) {
    let path = key;
    if (folder) {
        path = `${folder}/${key}`;
    }

    const existingFile = await loadCachedItem(path);

    let headers = {};
    if (existingFile) {
        headers['if-modified-since'] = existingFile.lm;
    }

    try {
        const response = await axios.get(`${globalVariables.source}/${path}`, {
            timeout: 15000,
            headers,
        });
        if (response.status == 200) {
            setItem(path, {
                lm: response.headers['last-modified'],
                data: response.data,
            });

            return response.data;
        }
    } catch (e) {
        //
    }

    if (!existingFile && localVersion) {
        const messages = await import(
            /* webpackChunkName: "local-[request]" */
            `../${path}`
        );

        return messages.default;
    } else if (existingFile) {
        return existingFile.data;
    }

    return null;
}

async function loadLocalItem(path) {
    const existingFile = await loadCachedItem(path);
    if (existingFile) {
        return existingFile;
    }

    try {
        const response = await axios.get(path);
        if (response.status == 200) {
            setItem(path, response.data);

            return response.data;
        }
    } catch (e) {
        //
    }

    return null;
}

export async function loadLanguage(locale) {
    return await loadItem('locales', `${locale}.json`, false);
}

export async function loadAllLanguages() {
    return await loadItem('locales', 'locales.json', false);
}

export async function loadConfiguration(key) {
    return await loadItem('clients', `${key}.json`, false);
}

export async function loadVersions() {
    return await loadItem(false, `releases.json`, false);
}

export default class Spark_Externals {
    constructor(p) {
        this._ = p;
    }

    async formulateClientCode(clientcode) {
        if (clientcode.includes(':') || clientcode.includes('-')) {
            let split = clientcode.trim().split(/[-:]/);
            clientcode = split[0];

            await this._.store.commit('config/setTag', split[1]);
        } else {
            await this._.store.commit('config/setTag', '');
        }

        await this._.store.dispatch('config/loadConfig', clientcode);

        const { configuration } = this._.store.state.config;

        return Object.keys(configuration).length ? configuration : false;
    }

    loadLocalItem = loadLocalItem;
}
